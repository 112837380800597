// Route elements
import { Phone } from "./screens/Phone.tsx"
import { USHomeAddress } from "./screens/HomeAddress.tsx"
import { Income } from "./screens/Income.tsx"
import { OTPScreen } from "./screens/OTP.tsx"
import { UserInfoScreen } from "./screens/UserInfo.tsx"
import { Complete } from "./screens/Complete.tsx"
import { AppEvent, AppScreen, AppState, ScreenKind, calculateRPCScreen } from "./onboardingModel.ts"
import {
  AppApiContext,
  addSignUp,
  authenticateSmsOtp,
  fetchCardConfigs,
  generateSmsOtp,
  openAccount,
  submitCardConfiguration,
  UserMetadata,
  generateVeriffSession,
  getActiveVeriffSessionUsingPost,
  queryVeriffDecision,
  addDeclineReason,
  queryEffectivDecision,
  submitFullSsnOrItin,
  submitCredit,
  generatePlaidLink,
  checkPlaidSessionStatus,
  fetchUserFromTokens,
  updateEmail,
  updateName,
  upsertDateOfBirth,
  upsertAddress,
} from "./api/webRoutes.tsx"
import { match } from "@pomebile/shared/tagged-union"
import { SubmittingApplicationScreen } from "./screens/SubmittingApplication.tsx"
import { UnsecuredOffer } from "./screens/UnsecuredOffer/UnsecuredOffer.tsx"
import { SecuredOffer } from "./screens/SecuredOffer/SecuredOffer.tsx"
import { CardSelector } from "./screens/CardSelector/CardSelector.tsx"
import { DocumentType, CHAgreement } from "@pomebile/pomelo-service-api"
import { TimeLimitErrorScreen } from "./screens/TimeLimitError.tsx"
import { GeneralErrorScreen } from "./screens/GeneralError.tsx"
import { PostDownloadScreen } from "./screens/PostDownload.tsx"
import { AuthContext, AuthData, parseJwt, Tokens } from "./api/authContext.ts"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools.tsx"
import { anonymousIdPromise } from "./utils/segment.ts"
import { ManualReviewScreen } from "./screens/ManualReview.tsx"
import { RejectedScreen } from "./screens/Rejected.tsx"
import { BUILD_MODE, MARKETING_WEBSITE } from "./envConstants.ts"
import { initializeEffectivSession } from "./utils/effectiv-manager.ts"
import {
  InitialData,
  ShellState,
  createAuthContext,
  createLoggingContext,
} from "./sharedShellLogic.tsx"
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk"
import { Veriff } from "./screens/Veriff.tsx"
import { CreditFrozen } from "./screens/CreditFrozen.tsx"
import { OpenAppScreen } from "./screens/OpenApp.tsx"
import { HandoffMessage } from "@pomebile/shared/api"
import { ErrorData } from "./api/errorContext.ts"
import { ReturningUserLoader } from "./screens/ReturningUserLoader.tsx"
import { VeriffSdkResponse } from "./screens/Veriff/rpcVeriffStateMachine.ts"
import { ReviewScreen } from "./screens/ReviewScreen.tsx"
import { VerifyIdentity } from "./screens/VerifyIdentity.tsx"
import { ExistingAccountError } from "./screens/ExistingAccountErrorScreen.tsx"
import { CreditFrozenMaxAttemptsError } from "./screens/CreditFrozenMaxAttemptsError.tsx"

export type Progression = "apply" | "accept-plan" | "download-app" | "none"

export const screenProgressionMap: Record<ScreenKind, Progression> = {
  ReturningUserLoader: "none",
  UserInfo: "apply",
  HomeAddress: "apply",
  Income: "apply",
  VerifyIdentity: "apply",
  Veriff: "apply",
  CardSelector: "accept-plan",
  SecuredOffer: "accept-plan",
  UnsecuredOffer: "accept-plan",
  Phone: "none",
  OTP: "none",
  SubmittingApplicationScreen: "none",
  ManualReview: "none",
  TimeLimitError: "none",
  GeneralError: "none",
  PostDownload: "none",
  Rejected: "none",
  Complete: "none",
  FrozenCredit: "none",
  ExistingAccountError: "none",
  Review: "apply",
  ReviewHomeAddress: "apply",
  ReviewVerifyIdentity: "apply",
  ReviewUserInfo: "apply",
  FrozenCreditMaxAttemptsError: "none",
}

export const calculateRPCProgression = (screen: AppScreen): Progression =>
  screenProgressionMap[screen.tag]

const searchParams = new URLSearchParams(document.location.search)
// Ad attribution params / click IDs
const urlParam = (name: string): string | undefined => {
  return searchParams.get(name) ?? undefined
}

const fbclid = urlParam("fbclid")

const userMetadata: UserMetadata = {
  utmCampaign: urlParam("utm_campaign"),
  utmSource: urlParam("utm_source"),
  utmMedium: urlParam("utm_medium"),
  utmContent: urlParam("utm_content"),
  utmTerm: urlParam("utm_term"),
  additionalTags: {
    fbclid,
    fbp: urlParam("_fbp"),
    fbc: urlParam("_fbc"),
    gclid: urlParam("gclid"),
    g_client_id: urlParam("g_client_id"),
    ttclid: urlParam("ttclid"),
    lead_id: urlParam("lead_id"),
    promo_code: urlParam("promo_code"),
    referrer_id: urlParam("referrer_id"),
    signup_url: document.location.origin,
  },
}

// Returns a function that closes the WebView/redirects to the app if we
// are configured to send events to the app. Returns undefined if not.
// RN postMessage docs: https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#postmessagestr
// (New version) ExpoBrowser docs: https://docs.expo.dev/versions/latest/sdk/webbrowser/
const getNavigateToAppFunction = ({
  isEmbedded,
  callbackURI,
  auth,
  apiCx,
}: {
  isEmbedded: boolean
  callbackURI: string | undefined
  auth: AuthData
  apiCx: AppApiContext
}): (() => void) | undefined => {
  if (!isEmbedded) {
    return undefined
  }

  if (!("ReactNativeWebView" in window) && callbackURI === undefined) {
    return undefined
  }

  // For safety, on prod lets only redirect to pomelo scheme. Local builds might end up using a exp:// scheme, so only prod needs this check.
  if (
    BUILD_MODE === "production" &&
    callbackURI &&
    !callbackURI.startsWith("pomelo://") &&
    !callbackURI.startsWith("pomelotest://")
  ) {
    return undefined
  }

  const requestBody: HandoffMessage = {
    type: "onboardingHandoff",
    deviceIdent: apiCx.deviceIdent,
    tokens: auth.tokens,
  }

  return function handoffToApp() {
    const message = JSON.stringify(requestBody)

    // TODO deprecate ReactNativeWebView handoff once clients are using the new version with redirects
    if ("ReactNativeWebView" in window) {
      (window as any).ReactNativeWebView.postMessage(message)
    } else {
      window.location.replace(`${callbackURI}?message=${encodeURIComponent(message)}`)
    }
  }
}

export const renderRPCScreen = (
  screen: AppScreen,
  send: (ev: AppEvent) => void,
  apiCx: AppApiContext,
  authCx: AuthContext,
): JSX.Element =>
  match(
    screen,
    {
      ReturningUserLoader: ({ persistedTokens }) => (
        <ReturningUserLoader
          api={{
            refreshUserFromTokens: async (tokens: Tokens) => {
              const parsedJwt = parseJwt(persistedTokens.token)
              if (!parsedJwt.valid) {
                sessionStorage.clear()
                // send unexpected errors here:
                send(AppEvent.EncounteredGeneralError({ errorType: "rpc" }))
                return
              }

              const { userIdent, email } = parsedJwt

              const authData: AuthData = {
                tokens: {
                  token: persistedTokens.token,
                  refreshToken: persistedTokens.refreshToken,
                },
                userIdent,
                email,
              }

              const res = await fetchUserFromTokens(apiCx, authData, authCx)

              if (!res.user) {
                send(AppEvent.EncounteredGeneralError({ errorType: "rpcReturningUserLoaderError" }))
              } else {
                send(
                  AppEvent.UserFetchCompleted({
                    phoneNumber: res.user.personalInfo.phone.phoneNumber,
                    authResult: {
                      tag: "existingUser",
                      token: tokens.token,
                      refreshToken: tokens.refreshToken,
                      user: res.user,
                    },
                  }),
                )
              }
            },
          }}
          persistedTokens={persistedTokens}
        />
      ),

      Phone: () => (
        <Phone
          country="US"
          onDone={(_country, phoneNumber) => send(AppEvent.PhoneNumberSubmitted(phoneNumber))}
        />
      ),

      OTP: ({ phoneNumber }) => (
        <OTPScreen
          api={{
            requestOTPCode: () => generateSmsOtp(apiCx, "US", phoneNumber),
            verifyOTPCode: (code) => authenticateSmsOtp(code, "US", phoneNumber, apiCx),
          }}
          phoneNumber={phoneNumber}
          country="US"
          onDone={(authResult) => {
            const { tag } = authResult
            if (tag === "disabledUser") {
              // Intentionally vague so users don't know we've labelled them as fraud/disabled
              // AuthenticationException = disabled user (could be either fraud or non-fraud)
              // Not a real error — just want it to look like one to users
              send(AppEvent.EncounteredGeneralError({ errorType: "rpcAuthenticationException" }))
            } else {
              send(AppEvent.AuthCompleted({ authResult }))
            }
          }}
        />
      ),

      UserInfo: ({ smsToken, phoneNumber }) => (
        <UserInfoScreen
          api={{
            submit: async (details) => {
              const res = await addSignUp(smsToken, details, userMetadata, apiCx)

              if (res.ok) {
                send(AppEvent.SignedUp({ personalInfo: details, signUpResult: res.response }))
                return {
                  ok: true,
                }
              }

              return {
                ok: false,
                error: "userAlreadyExists",
              }
            },
          }}
          showTermsAndMarketing
          headerTxt="Your personal details"
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            optInMarketing: false,
            phoneNumber,
          }}
        />
      ),

      ReviewUserInfo: ({ application, auth }) => (
        <UserInfoScreen
          api={{
            submit: async (details) => {
              const [nameRes, emailRes] = await Promise.all([
                updateName(details, apiCx, auth, authCx),
                updateEmail(details, apiCx, auth, authCx),
              ])

              if (nameRes.tag === "ok" && emailRes.tag === "ok") {
                send(
                  AppEvent.ReviewedUserInfo({
                    personalInfo: {
                      ...details,
                      dateOfBirth: application.personalInfo.dateOfBirth,
                    },
                  }),
                )
                return {
                  ok: true,
                }
              } else {
                return {
                  ok: false,
                  error: "userAlreadyExists",
                }
              }
            },
          }}
          showTermsAndMarketing={false}
          onCancel={() => send(AppEvent.ReviewedInfo({ application, auth, screen: "review" }))}
          headerTxt="Edit your details"
          initialValues={{
            firstName: application.personalInfo.firstName,
            lastName: application.personalInfo.lastName,
            dateOfBirth: application.personalInfo.dateOfBirth,
            email: application.personalInfo.email,
            phoneNumber: application.personalInfo.phoneNumber,
            optInMarketing: false,
          }}
        />
      ),

      VerifyIdentity: ({ auth, application }) => (
        <VerifyIdentity
          api={{
            submit: async (ssn, dateOfBirth) => {
              const res = await upsertDateOfBirth({ dateOfBirth }, apiCx, auth, authCx)

              if (res.tag === "ok") {
                send(
                  AppEvent.VerifiedIdentity({
                    ssn,
                    dateOfBirth: new Date(dateOfBirth[0], dateOfBirth[1] - 1, dateOfBirth[2]),
                  }),
                )
                return { ok: true }
              }

              return { ok: false }
            },
          }}
          initialValues={{
            ssn: "",
            dateOfBirth: application.personalInfo.dateOfBirth,
          }}
          headerTxt="Verify your identity"
        />
      ),

      ReviewVerifyIdentity: ({ application, auth }) => (
        <VerifyIdentity
          api={{
            submit: async (ssn, dateOfBirth) => {
              const res = await upsertDateOfBirth({ dateOfBirth }, apiCx, auth, authCx)
              if (res.tag === "ok") {
                send(
                  AppEvent.ReviewedVerifyIdentity({
                    ssn,
                    dateOfBirth: new Date(dateOfBirth[0], dateOfBirth[1] - 1, dateOfBirth[2]),
                  }),
                )
                return { ok: true }
              }

              return { ok: false }
            },
          }}
          headerTxt="Edit your info"
          onCancel={() => send(AppEvent.ReviewedInfo({ application, auth, screen: "review" }))}
          initialValues={{
            ssn: application.ssn ?? "",
            dateOfBirth: application.personalInfo.dateOfBirth,
          }}
        />
      ),

      Review: ({ application, auth }) => (
        <ReviewScreen
          api={{
            submitSsnOrItin: (ssn, identifierType) =>
              submitFullSsnOrItin(ssn, identifierType, apiCx, auth, authCx),
          }}
          onDone={(outcome) => {
            switch (outcome) {
              case "approved":
              case "manualReview":
              case "rejected":
                send(AppEvent.CompletedKyc(outcome))

                break
              case "requiresVeriff":
                send(AppEvent.RequiredVeriff())
                break
              case "unexpectedResult":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rpcVerifyIdentityUnexpectedResult",
                  }),
                )
                break
            }
          }}
          application={application}
          onReviewInfo={(info) => {
            send(AppEvent.ReviewedInfo({ application, auth, screen: info }))
          }}
        />
      ),

      Income: ({ application, auth }) => (
        <Income
          mtpRatePromo={application.mtpRatePromo}
          api={{
            generatePlaidLink: () => generatePlaidLink(apiCx, auth, authCx),
            checkPlaidSessionStatus: () => checkPlaidSessionStatus(apiCx, auth, authCx),
          }}
          onDone={(income) => send(AppEvent.ReportedIncome(income))}
        />
      ),

      HomeAddress: ({ auth }) => (
        <USHomeAddress
          api={{
            submit: async (address) => {
              const res = await upsertAddress(
                {
                  city: address.city,
                  countryCode: "US",
                  lineOne: address.addressLineOne,
                  lineTwo: address.addressLineTwo ?? "",
                  region: address.state,
                  zip: address.zip ?? "",
                },
                apiCx,
                auth,
                authCx,
              )

              if (res.tag === "ok") {
                send(AppEvent.AddedAddress(address))
                return { ok: true }
              }

              return { ok: false, type: "validationErr" }
            },
          }}
          headerTxt="Your home address"
          initialValues={{
            addressLineOne: "",
            city: "",
            state: "",
            zip: "",
            country: "US",
          }}
        />
      ),

      ReviewHomeAddress: ({ application, auth }) => (
        <USHomeAddress
          api={{
            submit: async (address) => {
              const res = await upsertAddress(
                {
                  city: address.city,
                  countryCode: "US",
                  lineOne: address.addressLineOne,
                  lineTwo: address.addressLineTwo ?? "",
                  region: address.state,
                  zip: address.zip ?? "",
                },
                apiCx,
                auth,
                authCx,
              )

              if (res.tag === "ok") {
                send(AppEvent.ReviewedAddress(address))
                return { ok: true }
              }

              return { ok: false, type: "validationErr" }
            },
          }}
          headerTxt="Edit your address"
          onCancel={() => send(AppEvent.ReviewedInfo({ application, auth, screen: "review" }))}
          initialValues={{
            addressLineOne: application.address?.addressLineOne ?? "",
            addressLineTwo: application.address?.addressLineTwo,
            city: application.address?.city ?? "",
            state: application.address?.state ?? "",
            zip: application.address?.zip ?? "",
            country: "US",
          }}
        />
      ),

      SubmittingApplicationScreen: ({ application, auth }) => (
        <SubmittingApplicationScreen
          api={{
            submitApplication: () => {
              const { mtpRatePromo, statedIncome } = application
              // Terms docs that users agree to before submitting their application
              // Same b/n revolving and charge card, so static list here
              const preOfferDocs: DocumentType[] = [
                DocumentType.PomeloTerms,
                DocumentType.CoastalTerms,
                DocumentType.Esign,
              ]

              // This promo is for MTP and only specific users qualify for it. We want users to agree to the terms of this promo before it gets applied to their transactions.
              if (mtpRatePromo) {
                preOfferDocs.push(DocumentType.MtpPromoTerms)
              }

              return submitCredit(
                {
                  documents: preOfferDocs,
                  statedIncome: statedIncome ?? 0, // stated income shouldn't be zero at this point but this is currently a limitation of our system typing
                },
                apiCx,
                auth,
                authCx,
              )
            },
          }}
          onDone={(outcome) => {
            switch (outcome.tag) {
              case "reachedTimeLimit":
                send(
                  AppEvent.TimeLimitExceeded({
                    errorType: "rpcSubmitApplicationReachedTimeLimit",
                    email: application.personalInfo.email,
                  }),
                )
                break
              case "errorSubmittingApplication":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rpcSubmitApplicationErrorSubmitting",
                  }),
                )
                break
              case "approvedSecured":
                send(
                  AppEvent.ApplicationSubmitted({
                    ...outcome,
                    updatedMtpRatePromo: application.mtpRatePromo,
                  }),
                )
                break
              case "approvedUnsecured":
                send(
                  AppEvent.ApplicationSubmitted({
                    ...outcome,
                    updatedMtpRatePromo: application.mtpRatePromo,
                    approvedLimit: outcome.approvedLimit,
                  }),
                )
                break
              case "frozenCreditMaxAttemptsError":
                send(
                  AppEvent.FrozenCreditPullAttemptsExceededError({
                    application,
                    auth,
                  }),
                )
                break
              default:
                send(AppEvent.ApplicationSubmitted(outcome))
            }
          }}
        />
      ),

      UnsecuredOffer: ({ processingOffer, auth }) => {
        return (
          <UnsecuredOffer
            api={{
              openAccount: () => {
                return openAccount(
                  {
                    accountType: "REMITTER",
                    creditAppIdent: processingOffer.creditApplicationIdent,
                    userIdent: processingOffer.userIdent,
                    chAgreement: processingOffer.cardholderAgreement,
                  },
                  apiCx,
                  auth,
                  authCx,
                )
              },
              fetchCardConfigs: () => fetchCardConfigs(apiCx, auth, authCx),
              declineReason: (reason) =>
                addDeclineReason(reason, auth.userIdent, apiCx, auth, authCx),
            }}
            mtpRatePromo={processingOffer.mtpRatePromo}
            approvedLimit={processingOffer.approvedLimit}
            cardholderAgreement={processingOffer.cardholderAgreement}
            onDone={(acceptResult) => {
              const { tag } = acceptResult

              if (tag === "accepted") {
                send(
                  AppEvent.AcceptedUnsecuredOffer({
                    cards: acceptResult.cards,
                    productGroupIdent: acceptResult.productGroupIdent,
                    updatedMtpRatePromo: acceptResult.updatedMtpRatePromo,
                  }),
                )
              } else if (tag === "declined") {
                window.location.href = MARKETING_WEBSITE
              } else if (tag === "noProductGroupIdent") {
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rpcUnsecuredOfferNoProductGroupIdent",
                  }),
                )
              } else {
                send(AppEvent.EncounteredGeneralError({ errorType: "rpcUnsecuredOfferCatchAll" }))
              }
            }}
          />
        )
      },

      SecuredOffer: ({ processingOffer, auth }) => {
        return (
          <SecuredOffer
            api={{
              openAccount: () =>
                openAccount(
                  {
                    accountType: "REMITTER",
                    creditAppIdent: processingOffer.creditApplicationIdent,
                    userIdent: processingOffer.userIdent,
                    chAgreement: processingOffer.cardholderAgreement,
                  },
                  apiCx,
                  auth,
                  authCx,
                ),
              declineReason: (reason) =>
                addDeclineReason(reason, auth.userIdent, apiCx, auth, authCx),
            }}
            mtpRatePromo={processingOffer.mtpRatePromo}
            cardholderAgreement={processingOffer.cardholderAgreement}
            onDone={(acceptResult) => {
              const { tag } = acceptResult

              if (tag === "accepted") {
                send(
                  AppEvent.AcceptedSecuredOffer({
                    productGroupIdent: acceptResult.productGroupIdent,
                    updatedMtpRatePromo: acceptResult.updatedMtpRatePromo,
                  }),
                )
              } else if (tag === "declined") {
                window.location.href = MARKETING_WEBSITE
              } else if (tag === "noProductGroupIdent") {
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rpcSecuredOfferNoProductGroupIdent",
                  }),
                )
              } else {
                send(AppEvent.EncounteredGeneralError({ errorType: "rpcSecuredOfferCatchAll" }))
              }
            }}
          />
        )
      },

      CardSelector: ({ processingOffer, auth }) => (
        <CardSelector
          api={{
            submitCardConfiguration: async (cardId: string) =>
              submitCardConfiguration(
                {
                  cardId,
                  userIdent: processingOffer.userIdent,
                },
                apiCx,
                auth,
                authCx,
              ),
          }}
          nameOnCard={`${processingOffer.personalInfo.firstName} ${processingOffer.personalInfo.lastName}`}
          address={processingOffer.address}
          cardOptions={processingOffer.cardOptions}
          onDone={(cardColor) => send(AppEvent.SelectedCard(cardColor))}
        />
      ),

      Veriff: ({ application, auth }) => (
        <Veriff
          api={{
            generateVeriffSession: () =>
              generateVeriffSession(application.userIdent, apiCx, auth, authCx),
            getActiveVeriffSession: () =>
              getActiveVeriffSessionUsingPost(application.userIdent, apiCx, auth, authCx),
            queryDecision: async () => {
              // Fetch the result of the user's Veriff session
              const veriffResponse = await queryVeriffDecision(
                application.userIdent,
                apiCx,
                auth,
                authCx,
              )

              if (veriffResponse.tag === "requires_resubmission") {
                return {
                  tag: "requires_resubmission",
                  reason: veriffResponse.reason,
                }
              }

              if (veriffResponse.tag === "pending") {
                return { tag: "pending" }
              }

              // Handle terminal Veriff outcomes by cross-checking Effectiv evaluation
              if (
                veriffResponse.tag === "approved" ||
                veriffResponse.tag === "declined" ||
                veriffResponse.tag === "review"
              ) {
                const { tag } = await queryEffectivDecision(apiCx, auth, authCx)

                if (tag === "unsupportedResult") {
                  return { tag: "pending" }
                }

                if (tag === "APPROVE") {
                  return { tag: "approved" }
                }

                if (tag === "DECLINE") {
                  return { tag: "rejected" }
                }

                if (tag === "CANCEL" || tag === "REVIEW") {
                  return { tag: "manualReview" }
                }
              }

              // Fallback for cases not handled above
              return { tag: "requires_support" }
            },
            startVeriffSdk: (url) => {
              const veriffResultProm = new Promise<VeriffSdkResponse>((resolve) => {
                createVeriffFrame({
                  url,
                  onEvent: (ev) => {
                    switch (ev) {
                      case MESSAGES.FINISHED:
                        resolve({ type: "finished" })
                        break
                      case MESSAGES.RELOAD_REQUEST:
                        resolve({ type: "reload_required" })
                        break
                      case MESSAGES.CANCELED:
                        resolve({ type: "user_cancelled" })
                    }
                  },
                  onReload: () => {
                    resolve({ type: "reload_required" })
                  },
                })
              })
              return veriffResultProm
            },
          }}
          onDone={(outcome) => {
            const { tag } = outcome

            switch (tag) {
              case "approved":
              case "manualReview":
              case "rejected":
                // KYC is complete
                send(AppEvent.CompletedKyc(tag))
                break
              case "requires_support":
                send(AppEvent.RequiredSupport())
                break
              case "pending":
                send(AppEvent.RequestTimedOut())
                break
              case "requires_resubmission":
                send(
                  AppEvent.EncounteredGeneralError({ errorType: "rpcVeriffRequiredResubmission" }),
                )
                break
            }
          }}
        />
      ),

      Complete: ({ auth, mtpRatePromo, productType }) => {
        const navigateToApp = getNavigateToAppFunction({
          isEmbedded: urlParam("mode") === "embedded",
          callbackURI: urlParam("redirect"),
          auth,
          apiCx,
        })
        return navigateToApp ? (
          <OpenAppScreen navigateToApp={navigateToApp} />
        ) : (
          <Complete mtpRatePromo={mtpRatePromo} productType={productType} />
        )
      },

      TimeLimitError: ({ email }) => <TimeLimitErrorScreen email={email} />,

      GeneralError: () => <GeneralErrorScreen />,

      PostDownload: ({ auth, recipientFirstName }) => {
        const navigateToApp = getNavigateToAppFunction({
          isEmbedded: urlParam("mode") === "embedded",
          callbackURI: urlParam("redirect"),
          auth,
          apiCx,
        })

        return navigateToApp ? (
          <OpenAppScreen navigateToApp={navigateToApp} />
        ) : (
          <PostDownloadScreen recipientFirstName={recipientFirstName} />
        )
      },

      ManualReview: () => <ManualReviewScreen />,

      Rejected: () => <RejectedScreen />,

      FrozenCredit: ({ auth, application }) => (
        <CreditFrozen
          api={{
            resubmitApplication: () => {
              const { mtpRatePromo, statedIncome } = application

              // Terms docs that users agree to before submitting their application
              // Same between revolving and charge card, so static list here
              const preOfferDocs: DocumentType[] = [
                DocumentType.PomeloTerms,
                DocumentType.CoastalTerms,
                DocumentType.Esign,
              ]

              // This promo is for GCash and only specific users qualify for it. We want users to agree to the terms of this promo before it gets applied to their transactions.
              if (mtpRatePromo) {
                preOfferDocs.push(DocumentType.MtpPromoTerms)
              }

              return submitCredit(
                {
                  documents: preOfferDocs,
                  statedIncome: statedIncome ?? 0, // stated income shouldn't be zero at this point but this is currently a limitation of our system typing
                },
                apiCx,
                auth,
                authCx,
              )
            },
          }}
          onDone={(outcome) => {
            switch (outcome.tag) {
              case "errorSubmittingApplication":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rpcFrozenCreditSubmitApplicationErrorSubmitting",
                  }),
                )
                break
              case "frozenCreditMaxAttemptsError":
                send(
                  AppEvent.FrozenCreditPullAttemptsExceededError({
                    application,
                    auth,
                  }),
                )
                break

              case "approvedSecured":
              case "approvedUnsecured":
                send(
                  AppEvent.ApplicationSubmitted({
                    ...outcome,
                    updatedMtpRatePromo: application.mtpRatePromo,
                  }),
                )
                break

              default:
                send(AppEvent.ApplicationSubmitted(outcome))
            }
          }}
        />
      ),

      ExistingAccountError: () => <ExistingAccountError />,
      FrozenCreditMaxAttemptsError: () => <CreditFrozenMaxAttemptsError />,
    },

    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export function initRPCOnboarding(
  apiCx: AppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<AppState, AppScreen> {
  // TODO next person to add a logger must convert this to an array

  const logger = createLoggingContext({
    experiments: {
      // Add any experiment tracking here!
      // The naming pattern should be as follows:
      //   "experiment-name": "not-testing" -> while we are not running the actual A/B test, we should track any such data separately so we can filter the A/B variants exactly
      //   "experiment-name": "experiment-enabled" for control variant deployment, and then "experiment-name": "experiment-enabled" for the experiment variant deployment.
    },
  })

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: AppState; apiCx: AppApiContext } = JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateRPCScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: logger,
        }
      }
    }
  }

  const authContext = createAuthContext(apiCx)
  const persistedTokens = authContext.getCachedAuthTokens()

  let initialState: ShellState<AppState, AppScreen>

  if (persistedTokens) {
    initialState = {
      state: AppState.FetchReturningUser({ persistedTokens }),
      screens: [AppScreen.ReturningUserLoader({ persistedTokens })],
    }
  } else {
    initialState = {
      state: AppState.Initial({ phoneNumber: apiCx.phoneNumber }),
      screens: [
        apiCx.phoneNumber ? AppScreen.OTP({ phoneNumber: apiCx.phoneNumber }) : AppScreen.Phone(),
      ],
    }
  }

  anonymousIdPromise?.then((id) => {
    apiCx.anonymousId = id
  })

  initializeEffectivSession()
    .then((res) => {
      if (res.ok) {
        apiCx.effectivSessionId = res.effectivSessionId
      }
      if (!res.ok && res.type === "InvalidEffectivSessionId") {
        logger.logError(new Error("InvalidEffectivSessionId"))
      }
    })
    .catch((err) => logger.logError(err))

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: logger,
  }
}

// const { initialState, apiCx, authCx, logging } = init(appApiCx)

export const getRPCAuth = (state: AppState): AuthData | undefined => {
  // TODO Some of these states are auth'ed, but unlikely to not go through an auth'ed state first
  return match(
    state,
    {
      FetchReturningUser: () => undefined,
      TimeoutError: (): AuthData | undefined => undefined,
      GeneralError: (): AuthData | undefined => undefined,
      Initial: () => undefined,
      Finished: () => undefined,
      RequiredCustomerSupport: () => undefined,
      SigningUp: () => undefined,
      Complete: ({ auth }) => auth,
      ExistingAccountError: () => undefined,
    },
    ({ data: { auth } }) => auth,
  )
}

export const getRPCError = (event: AppEvent): ErrorData | undefined => {
  return match(
    event,
    {
      TimeLimitExceeded: ({ errorType }): ErrorData | undefined => ({ errorType }),
      EncounteredGeneralError: ({ errorType }): ErrorData | undefined => ({ errorType }),
    },
    () => undefined,
  )
}
