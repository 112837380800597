import { useMemo, useState } from "react"
import * as Yup from "yup"
import { TextField } from "@pomebile/design-system"
import { Button, HStack, Secure, Txt, VStack } from "@pomebile/primitives"
import { ScreenForm } from "../components/ScreenForm"
import * as V from "../utils/formValidation/validationMessages"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import { USAddress } from "../api/webRoutes"
import { Combobox } from "../components/Combobox"
import { useAddressSuggestions, Suggestion } from "@pomebile/shared/hooks"
import { validateZipAgainstState } from "@pomebile/shared/helpers"
import usRegions from "../utils/regions/us.json"
import { ConfirmAddressDialog } from "./HomeAddress/ConfirmAddressDialog"
import { sentryErrorLogger } from "../utils/sentry"
import { segmentAnalyticsLogger } from "../utils/segment"

const notPoBoxRegex = /^((?!po\s?box|p\.?o\.?\s?box).)*$/i

const usStatesNames = usRegions.map(({ name }) => name)
const usStatesShortCodes = usRegions.map(({ shortCode }) => shortCode)
const findUsRegionByName = (regionName: string) => usRegions.find(({ name }) => name === regionName)
const findUsRegionByShortCode = (regionShortCode: string) =>
  usRegions.find(({ shortCode }) => shortCode === regionShortCode)
const filterUsRegions = (searchTerm: string) => {
  const term = searchTerm.toLowerCase()
  return usRegions.filter(({ name, shortCode }) => {
    const sanitizedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    return name.toLowerCase().match(sanitizedTerm) || shortCode.toLowerCase().match(sanitizedTerm)
  })
}

type USAddressSchema = Omit<USAddress, "zip"> & { zip: string }
export const USAddressSchema: Yup.ObjectSchema<USAddressSchema> = Yup.object().shape({
  addressLineOne: Yup.string()
    .matches(notPoBoxRegex, V.MSG_NO_PO_BOX)
    .required(V.MSG_REQUIRED)
    .max(44, V.MSG_MAX_CHARACTERS(44)),
  addressLineTwo: Yup.string().max(44, V.MSG_MAX_CHARACTERS(44)),
  city: Yup.string().max(50, V.MSG_MAX_CHARACTERS(50)).required(V.MSG_REQUIRED),
  state: Yup.string().oneOf(usStatesNames, V.MSG_INVALID_STATE).required(V.MSG_REQUIRED),
  zip: Yup.string()
    .matches(/(^\d{5}$)/, V.MSG_INVALID_ZIP)
    .required(V.MSG_REQUIRED),
  country: Yup.string().oneOf(["US"]).required(V.MSG_REQUIRED),
})

export const USAddressShortCodeSchema: Yup.ObjectSchema<USAddressSchema> = Yup.object().shape({
  addressLineOne: Yup.string()
    .matches(notPoBoxRegex, V.MSG_NO_PO_BOX)
    .required(V.MSG_REQUIRED)
    .max(44, V.MSG_MAX_CHARACTERS(44)),
  addressLineTwo: Yup.string().max(44, V.MSG_MAX_CHARACTERS(44)),
  city: Yup.string().max(50, V.MSG_MAX_CHARACTERS(50)).required(V.MSG_REQUIRED),
  state: Yup.string().oneOf(usStatesShortCodes, V.MSG_INVALID_STATE).required(V.MSG_REQUIRED),
  zip: Yup.string()
    .matches(/(^\d{5}$)/, V.MSG_INVALID_ZIP)
    .required(V.MSG_REQUIRED),
  country: Yup.string().oneOf(["US"]).required(V.MSG_REQUIRED),
})

type USHomeAddressResponse =
  | {
      ok: true
    }
  | {
      ok: false
      type: "validationErr"
    }

type USHomeAddresProps = {
  api: {
    submit: (address: USAddress) => Promise<USHomeAddressResponse>
  }
  headerTxt: string
  initialValues: {
    addressLineOne: string
    addressLineTwo?: string | undefined
    city: string
    state: string
    zip: string
    country: "US"
  }
  onCancel?: () => void
}

export function USHomeAddress({ api, headerTxt, initialValues, onCancel }: USHomeAddresProps) {
  const [showConfirmAddress, setShowConfirmAddress] = useState(false)
  const { suggestions, addressSuggestion, fetchSuggestions, searchAddress, areAddressesIdentical } =
    useAddressSuggestions()

  const addAddress = async (
    addressWithStateCode: USAddress,
  ): Promise<void | {
    status: "validationErr"
    err: {
      field: keyof USAddressSchema
      errorMessage: string
    }
  }> => {
    const res = await api.submit(addressWithStateCode)

    setShowConfirmAddress(false)
    if (res.ok) {
      return
    } else {
      return {
        status: "validationErr",
        err: { field: "addressLineOne", errorMessage: V.MSG_NO_PO_BOX },
      }
    }
  }

  const [submit, getFieldProps, status, { setError, setTouched }] = useForm({
    name: "Home Address", // Note: Must match 1.0 name for analytics
    schema: USAddressSchema,
    initial: {
      ...initialValues,
      state: findUsRegionByShortCode(initialValues.state)?.name ?? "",
    },
    submit: async (address) => {
      const stateShortCode = findUsRegionByName(address.state)?.shortCode
      if (!stateShortCode) {
        // Will never happen because we validate against the list of states but this is here to satisfy TS
        return {
          status: "validationErr",
          err: { field: "state", errorMessage: V.MSG_INVALID_STATE },
        }
      }

      const isValidZip = await validateZipAgainstState(address.zip, stateShortCode)

      if (!isValidZip) {
        return {
          status: "validationErr",
          err: { field: "zip", errorMessage: V.MSG_INVALID_ZIP },
        }
      }

      const suggestedAddress = await searchAddress({
        street: address.addressLineOne,
        secondary: address.addressLineTwo,
        city: address.city,
        state: stateShortCode,
        zipCode: address.zip,
      })

      const addressWithStateCode = { ...address, state: stateShortCode }

      if (suggestedAddress.result === "ok") {
        const isAddressEqual = areAddressesIdentical(
          {
            street: address.addressLineOne,
            secondary: address.addressLineTwo,
            city: address.city,
            state: stateShortCode,
            zipCode: address.zip,
          },
          suggestedAddress.address,
        )

        if (isAddressEqual) {
          return addAddress(addressWithStateCode)
        }

        segmentAnalyticsLogger.logEvent("Opened address suggestion drawer")
        setShowConfirmAddress(true)
        return
      }

      if (suggestedAddress.result === "error") {
        console.warn(suggestedAddress.message)
        sentryErrorLogger.logError(suggestedAddress.message)
      }

      return addAddress(addressWithStateCode)
    },
  })

  const {
    value: lineOne = "",
    errorText,
    onChange: onChangeLineOne,
    ...restOfLineOneProps
  } = getFieldProps("addressLineOne")
  const {
    value: lineTwo = "",
    onChange: onChangeLineTwo,
    ...restOfLineTwoProps
  } = getFieldProps("addressLineTwo")
  const { value: city = "", onChange: onChangeCity, ...restOfCityProps } = getFieldProps("city")
  const {
    onChange: onChangeState,
    onBlur: onBlurState,
    value: state = "",
    ...restOfStateProps
  } = getFieldProps("state")
  const { value: zip = "", onChange: onChangeZip, ...restOfZipProps } = getFieldProps("zip")

  const formatAddressSuggestion = (suggestion: Suggestion) => {
    return (
      <Secure>
        <Txt>
          {`${suggestion.streetLine}${
            suggestion.secondary.length > 0 ? ` ${suggestion.secondary}` : ""
          }, ${suggestion.city} ${suggestion.state}, ${suggestion.zipcode}`}
        </Txt>
      </Secure>
    )
  }

  const stateSuggestions = useMemo(() => {
    return filterUsRegions(state).map(({ name }) => name)
  }, [state])

  const handleStateBlur = () => {
    if (stateSuggestions.length === 1) {
      onChangeState(stateSuggestions[0]) // If input matches a single suggestion, set it as the state
    }
    onBlurState()
  }

  return (
    <>
      <ScreenForm onSubmit={submit}>
        <VStack gap="xl2" padding={{ bottom: "xl2" }}>
          <VStack gap="sm">
            <Txt variant="headline2" as="h1">
              {headerTxt}
            </Txt>

            <Txt variant="body1" as="p">
              Only residential addresses may be entered. P.O. Boxes and commercial addresses will
              not be accepted.
            </Txt>
          </VStack>

          <VStack gap="xs">
            <Secure>
              <Combobox
                options={suggestions}
                renderOption={formatAddressSuggestion}
                onSelectOption={(suggestion) => {
                  const { streetLine, secondary, city, state, zipcode } = suggestion

                  // Set field values when user selects an address from the combobox
                  onChangeLineOne(streetLine)
                  onChangeLineTwo(secondary)
                  onChangeCity(city)
                  onChangeState(findUsRegionByShortCode(state)?.name || "")
                  onChangeZip(zipcode)

                  if (!notPoBoxRegex.test(streetLine.toLowerCase())) {
                    setTouched("addressLineOne", true)
                    setError("addressLineOne", V.MSG_NO_PO_BOX)
                  }
                }}
              >
                <TextField
                  value={lineOne}
                  label="Address Line 1"
                  placeholder="Street Address..."
                  type="text"
                  onChange={(v) => {
                    onChangeLineOne(v)
                    v && fetchSuggestions(v)
                  }}
                  {...restOfLineOneProps}
                  errorText={errorText}
                />
              </Combobox>
              <TextField
                label="Address Line 2"
                placeholder="Building, Apt, Unit..."
                type="text"
                {...restOfLineTwoProps}
                value={lineTwo}
                onChange={onChangeLineTwo}
              />
            </Secure>
            <TextField
              value={city}
              label="City"
              placeholder="Enter City..."
              type="text"
              onChange={onChangeCity}
              {...restOfCityProps}
            />
            <HStack gap="sm">
              <VStack gap="xs">
                <Combobox
                  options={stateSuggestions}
                  renderOption={(option) => option}
                  onSelectOption={onChangeState}
                >
                  <TextField
                    label="State"
                    placeholder="Enter State..."
                    type="text"
                    onChange={(v) => {
                      onChangeState(v)
                    }}
                    onBlur={handleStateBlur}
                    value={state}
                    {...restOfStateProps}
                  />
                </Combobox>
              </VStack>
              <TextField
                label="Zip Code"
                type="text"
                placeholder="00000"
                value={zip}
                onChange={(value) => {
                  if (value.length > 5) {
                    return
                  }

                  onChangeZip(value)
                }}
                {...restOfZipProps}
              />
            </HStack>
          </VStack>
        </VStack>

        <StickyBottom>
          <FormSubmitButton status={status}>Continue</FormSubmitButton>
          {onCancel && (
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </StickyBottom>
      </ScreenForm>
      {addressSuggestion && (
        <ConfirmAddressDialog
          onClose={() => setShowConfirmAddress(false)}
          isOpen={showConfirmAddress}
          onConfirmAddress={async (address) => {
            const res = await addAddress(address)

            if (res) {
              setError(res.err.field, res.err.errorMessage)
            }
          }}
          defaultAddress={{
            addressLineOne: lineOne,
            addressLineTwo: lineTwo,
            city,
            state: findUsRegionByName(state)?.shortCode ?? "",
            zip,
            country: "US",
          }}
          suggestedAddress={{
            addressLineOne: addressSuggestion.street,
            addressLineTwo: addressSuggestion.secondary,
            city: addressSuggestion.city,
            state: addressSuggestion.state,
            zip: addressSuggestion.zipCode,
            country: "US",
          }}
        />
      )}
    </>
  )
}
