type AnyObject = { [k: string]: any }
type AnyShallowObject = { [k: string]: string }

// Represents an API error with an error HTTP status code returned.
export class ResponseStatusError extends Error {
  constructor(
    public route: AnyRoute,
    public request: unknown,
    public response: Response,
    public payload?: any,
  ) {
    super(`Request '${route.url}' failed with status code ${response.status}`)
  }
}

// Represents an error that occurred while making the network request.
export class NetworkError extends Error {
  constructor(
    public originalError: Error,
    public baseUrl: string,
    public route: AnyRoute,
  ) {
    super(`Fetching ${baseUrl} failed with network error: ${originalError.message}`)
  }
}

export class FetchError extends Error {
  constructor(
    public originalError: unknown,
    public baseUrl: string,
    public route: AnyRoute,
  ) {
    super(`Fetching ${baseUrl} failed with error: ${originalError}`)
  }
}

export type AnyRoute = {
  url: string
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH"
  contentType?: "application/json"
  meta: {
    req?: AnyObject
    res?: AnyObject | void
    pathParams?: AnyShallowObject
    queryParams?: AnyShallowObject | AnyObject
  }
}

export type RouteReqParams<TRoute extends AnyRoute> = Exclude<
  keyof TRoute["meta"],
  "res"
> extends never
  ? // don't look there, there will be dragons
    { [key: string | number | symbol]: never }
  : {
      [K in Exclude<keyof TRoute["meta"], "res">]: TRoute["meta"][K]
    }

export function isJson(response: Response) {
  const contentType = response.headers.get("content-type")
  return contentType && contentType.indexOf("application/json") !== -1
}

export async function issueRequest<const TRoute extends AnyRoute>(
  route: TRoute,
  reqParams: RouteReqParams<TRoute>,
  ctx: { baseUrl: string; headers: { [header: string]: string } },
  fetchFunc: typeof fetch = fetch,
): Promise<TRoute["meta"]["res"]> {
  let url = reqParams.queryParams
    ? `${route.url}${getQueryString(reqParams.queryParams)}`
    : route.url
  if (reqParams.pathParams) {
    for (const [segment, value] of Object.entries(reqParams.pathParams)) {
      url = url.replace(`{${segment}}`, encodeURIComponent(value))
    }
  }

  try {
    const response = await fetchFunc(ctx.baseUrl + url, {
      method: route.method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors",
      headers: route.contentType
        ? {
            "Content-Type": "application/json",
            ...ctx.headers,
          }
        : ctx.headers,
      // body data type must match "Content-Type" header
      body: reqParams.req ? JSON.stringify(reqParams.req) : undefined,
    })

    if (!response.ok) {
      return Promise.reject(
        new ResponseStatusError(
          route,
          reqParams,
          response,
          isJson(response) ? await response.json() : undefined,
        ),
      )
    }

    const contentType = response.headers.get("content-type")
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json()
    } else {
      return undefined
    }
  } catch (err: unknown) {
    if (err instanceof TypeError) {
      return Promise.reject(new NetworkError(err as Error, ctx.baseUrl, route))
    }
    return Promise.reject(new FetchError(err, ctx.baseUrl, route))
  }
}

// copied from https://github.com/pomelo-co/pomebile/pull/1191/files#diff-790a95bf4cc73eca1109ae27031f31b181705e17b4d0bd2bd4eacd9fc89bfb9aR49
// from openapi-typescript-codegen
const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = []

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
  }

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v)
        })
      } else if (typeof value === "object") {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v)
        })
      } else {
        append(key, value)
      }
    }
  }

  Object.entries(params).forEach(([key, value]) => {
    process(key, value)
  })

  if (qs.length > 0) {
    return `?${qs.join("&")}`
  }

  return ""
}

const isDefined = <T>(value: T | null | undefined): value is Exclude<T, null | undefined> =>
  value !== undefined && value !== null
