import type { StackComponent, DStackComponent } from "@pomebile/primitives"
import { sprinkles } from "../../styles/sprinkles.css"
import {
  BorderLongForm,
  BorderWidth,
  BorderWidthSide,
  BorderWidths,
} from "@pomebile/primitives/tokens"

/**
 * Removes `readonly` and adds `?` to all properties
 */
type MutablePartial<T> = { -readonly [P in keyof T]?: T[P] }
type PaddingSprinkles = MutablePartial<
  Pick<
    Parameters<typeof sprinkles>[0],
    | "padding"
    | "paddingBottom"
    | "paddingLeft"
    | "paddingRight"
    | "paddingTop"
    | "paddingX"
    | "paddingY"
  >
>
type BorderRadiusSprinkles = MutablePartial<
  Pick<
    Parameters<typeof sprinkles>[0],
    | "borderRadius"
    | "borderTopLeftRadius"
    | "borderTopRightRadius"
    | "borderBottomLeftRadius"
    | "borderBottomRightRadius"
    | "borderTopRadius"
    | "borderBottomRadius"
  >
>

type MarginSprinkles = MutablePartial<
  Pick<
    Parameters<typeof sprinkles>[0],
    "margin" | "marginBottom" | "marginLeft" | "marginRight" | "marginTop" | "marginX" | "marginY"
  >
>

export const Stack: StackComponent = ({
  direction = "column",
  alignItems,
  justifyContent,
  borderRadius,
  fill,
  children,
  gap,
  padding,
  border,
  width = "full",
  height,
  flex,
  overflow,
  overflowWrap,
  margin,
}) => {
  let borderStyle, borderColor, borderWidth
  const isBorderLongForm = border && !Array.isArray(border)
  const borderWidths: Record<BorderWidthSide, BorderWidth> = {
    borderBottomWidth: "none",
    borderLeftWidth: "none",
    borderRightWidth: "none",
    borderTopWidth: "none",
  }

  // Dev supplied short-form pattern
  if (Array.isArray(border)) {
    // Shortform implies adding borders to all sides
    borderWidth = border[0]
    borderColor = border[1]
    borderStyle = border[2]
  }

  if (undefined === borderColor) borderColor = (border as BorderLongForm)?.color
  if (isBorderLongForm) {
    const widths: BorderWidths | undefined = (border as BorderLongForm)?.widths
    if (widths?.top) borderWidths.borderTopWidth = widths?.top
    if (widths?.right) borderWidths.borderRightWidth = widths?.right
    if (widths?.bottom) borderWidths.borderBottomWidth = widths?.bottom
    if (widths?.left) borderWidths.borderLeftWidth = widths?.left
  }

  const paddings: PaddingSprinkles = {}
  if (typeof padding === "string") {
    paddings.padding = padding
  } else if (typeof padding === "object") {
    paddings.paddingTop = padding.top
    paddings.paddingRight = padding.right
    paddings.paddingBottom = padding.bottom
    paddings.paddingLeft = padding.left
    paddings.paddingX = padding.x
    paddings.paddingY = padding.y
  }

  const margins: MarginSprinkles = {}
  if (typeof margin === "string") {
    margins.margin = margin
  } else if (typeof margin === "object") {
    margins.marginTop = margin.top
    margins.marginRight = margin.right
    margins.marginBottom = margin.bottom
    margins.marginLeft = margin.left
    margins.marginX = margin.x
    margins.marginY = margin.y
  }

  const borderRadiusSprinkles: BorderRadiusSprinkles = {}
  if (typeof borderRadius === "string") {
    borderRadiusSprinkles.borderRadius = borderRadius
  } else if (typeof borderRadius === "object") {
    borderRadiusSprinkles.borderTopLeftRadius = borderRadius.topLeft
    borderRadiusSprinkles.borderTopRightRadius = borderRadius.topRight
    borderRadiusSprinkles.borderBottomLeftRadius = borderRadius.bottomLeft
    borderRadiusSprinkles.borderBottomRightRadius = borderRadius.bottomRight
    borderRadiusSprinkles.borderTopRadius = borderRadius.top
    borderRadiusSprinkles.borderBottomRadius = borderRadius.bottom
  }

  return (
    <div
      className={`
      ${sprinkles({
        display: "flex",
        flexDirection: direction,
        alignItems,
        borderStyle: borderStyle || "solid",
        borderColor: borderColor || "border-main",
        borderWidth: borderWidth || "none",
        ...(isBorderLongForm ? borderWidths : undefined),
        background: fill,
        width,
        height,
        gap,
        justifyContent,
        flex,
        overflow,
        overflowWrap,
      })} 
      ${sprinkles(paddings)}
      ${sprinkles(borderRadiusSprinkles)}
      ${sprinkles(margins)}
      `}
    >
      {children}
    </div>
  )
}

export const VStack: DStackComponent = (props) => {
  return <Stack {...props} direction="column" />
}

export const HStack: DStackComponent = (props) => {
  return <Stack {...props} direction="row" />
}
